import React, { useEffect, useContext } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AuthRoute, GuestRoute } from "./utils/CustomRouteTypes";
import Home from "./components/Home/Home";
import HomeProUser from "./components/Home/Home_customizedProUser";
import HomeOrgUpdate from "./components/Home/Home_customizedOrgUpdate";
import News from "./components/News/News";
import Leagues from "./components/Leagues/Leagues";
import LeaguesSingle from "./components/Leagues/LeaguesSingle/LeaguesSingle";
import MultiStageLeague from "./components/MultiStageLeague/MultiStageLeague";
import MultiStageLeaguesSingle from "./components/MultiStageLeague/MultiStageLeaguesSingle/MultiStageLeaguesSingle";
import Settings from "./components/Settings/Settings";
import UserProfile from "./components/UserProfile/UserProfile";
import Logout from "./components/Logout/Logout";
import PlayersRouter from "./components/Players/PlayersRouter";
import Organisations from "./components/Organisations/Organisations";
import OrganisationsSingle from "./components/Organisations/OrganisationsSingle/OrganisationsSingle";
import TeamsRouter from "./components/TeamsRouter/TeamsRouter";
import TempInvites from "./components/TempInvites/TempInvites";
import Notifications from "./components/Notifications/Notifications";
import Welcome from "./components/Welcome/Welcome";
import CoachProfile from "./components/Coaches/CoachProfile";
import NewsSingle from "./components/NewsSingle/NewsSingle";
import GameProfile from "./components/GameProfile/GameProfile";
import Tournaments from "./components/Tournaments/Tournaments";
import TournamentsSingle from "./components/Tournaments/TournamentsSingle/TournamentsSingle";
import Staff from "./components/Staff/Staff";
import Finder from "./components/Finder/Finder";
import Scrims from "./components/Scrims/Scrims";
import ScrimsSingle from "./components/Scrims/ScrimsSingle/ScrimsSingle";
import Overview from "./components/Overview/Overview";
import YourMatches from "./components/YourMatches/YourMatches";
import MatchPage from "./components/MatchPage/MatchPage";
import BrowseGames from "./components/BrowseGames/BrowseGames";
import BrowseGameSingle from "./components/BrowseGames/BrowseGameSingle/BrowseGameSingle";
import Streams from "./components/Streams/Streams";
import StreamsSingle from "./components/Streams/StreamsSingle/StreamsSingle";
import Rules from "./components/Rules/Rules";
import ConditionsOfUse from "./components/ConditionsOfUse/ConditionsOfUse";
import Contact from "./components/Contact/Contact";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import OrganisationsManage from "./components/Organisations/OrganisationsManage/OrganisationsManage";
import DynamicPage from "./components/DynamicPage/DynamicPage";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import { AppContext } from "./context/AppContext";
import SummonerRedirect from "./components/SummonerRedirect/SummonerRedirect";
import VerifyCSGO from "./components/_modals/CreatePlayerModal/ConnectGame/CSGO/VerifyCSGO";

import VerifyVL from "./components/_modals/CreatePlayerModal/ConnectGame/VL/VerifyVL";	
import VerifyLOL from "./components/_modals/CreatePlayerModal/ConnectGame/LOL/VerifyLOL";	

// Global router
const AppRouter = () => {
  const { pathname } = useLocation();
  const { showRouterComponent } = useContext(AppContext);
  const { selectedGame } = useContext(AppContext);
  const routes = [
    // {
    //   key: "DropDownTest",
    //   pathName: "/dropdown",
    //   content: <DropDownTest />,
    // },
    {
      key: "Overview",
      pathName: "/overview",
      content: <Overview />,
    },
    {
      key: "Leagues",
      pathName: "/leagues",
      content: <Leagues />,
    },
    {
      key: "Leagues",
      pathName: "/league/:id",
      content: <LeaguesSingle />,
    },
    {
      key: "Tournaments",
      pathName: "/tournaments",
      content: <Tournaments />,
    },
    {
      key: "Tournaments",
      pathName: "/tournament/:id",
      content: <TournamentsSingle />,
    },
    {
      key: "Multi Stage Leagues",
      pathName: "/multi-stage-league",
      content: <MultiStageLeague />,
    },
    {
      key: "Multi Stage Leagues",
      pathName: "/single-multi-stage-league/:id",
      content: <MultiStageLeaguesSingle />,
    },
    {
      key: "Scrim Finder",
      pathName: "/scrims",
      content: <Scrims />,
    },
    {
      key: "Scrim Finder",
      pathName: "/scrim/:id",
      content: <ScrimsSingle />,
    },
    {
      key: "Player Finder",
      pathName: "/finder",
      content: <Finder />,
    },
    {
      key: "News",
      pathName: "/news",
      content: <News />,
      exact: true,
    },
    {
      key: "News",
      pathName: "/news/all",
      content: <News />,
      exact: true,
    },
    {
      key: "News",
      pathName: "/news/announcements",
      exact: true,
      content: <News />,
    },
    {
      key: "News",
      pathName: "/news/leagues",
      content: <News />,
      exact: true,
    },
    {
      key: "News",
      pathName: "/news/tournaments",
      content: <News />,
      exact: true,
    },
    {
      key: "News",
      pathName: "/news/:slug",
      content: <NewsSingle />,
    },
    {
      key: "Teams",
      pathName: "/teams",
      content: <TeamsRouter />,
    },
    {
      key: "Organisations",
      pathName: "/organisations",
      content: <Organisations />,
    },
    {
      key: "Organisations",
      pathName: "/organisation/:id/manage",
      content: <OrganisationsManage />,
    },
    {
      key: "Organisations",
      pathName: "/organisation/:id",
      content: <OrganisationsSingle />,
    },
    {
      key: "Your Matches",
      pathName: "/your-matches",
      content: <YourMatches />,
    },
  ];
  const authRoutes = [
    {
      key: "Streams",
      pathName: "/streams",
      content: <Streams />,
    },
    {
      key: "Streams",
      pathName: "/stream/:id",
      content: <StreamsSingle />,
    },
  ];
  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  if (!showRouterComponent) return null;
  return (
    <Switch>
      {/* <Route exact path="/dropdown">
        <DropDownTest />
      </Route> */}
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/pro-user">
        <HomeProUser />
      </Route>
      <Route exact path="/org-update">
        <HomeOrgUpdate />
      </Route>
      <AuthRoute path="/logout">
        <Logout />
      </AuthRoute>
      <GuestRoute path="/reset-password/:email/:token">
        <ResetPassword />
      </GuestRoute>
      <AuthRoute path="/account">
        <Settings />
      </AuthRoute>
      <AuthRoute path="/verify/:steamId">
        <VerifyCSGO />
      </AuthRoute>      	
      <AuthRoute path="/verifyVL">	
        <VerifyVL />	
      </AuthRoute>

      <AuthRoute path="/verifyVL">		
        <VerifyVL />		
      </AuthRoute>	
      <AuthRoute path="/verifyLOL">		
        <VerifyLOL />		
      </AuthRoute>
      <Route path="/welcome">
        <Welcome />
      </Route>
      <Route path="/staff">
        <Staff />
      </Route>
      <Route path="/rules">
        <Rules />
      </Route>
      <Route path="/s/:slug">
        <DynamicPage />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/user/:id">
        <UserProfile />
      </Route>
      <Route path="/players">
        <PlayersRouter />
      </Route>
      <Route path="/summoner-redirect/:accountId">
        <SummonerRedirect />
      </Route>
      <Route path="/game-profile/:id">
        <GameProfile />
      </Route>
      <Route path="/coaches/:id">
        <CoachProfile />
      </Route>
      <Route path="/notifications">
        <Notifications />
      </Route>
      <Route path="/p/:slug">
        <DynamicPage />
      </Route>
      <Route path="/match/:id">
        <MatchPage />
      </Route>
      <AuthRoute path="/temp-invites">
        <TempInvites />
      </AuthRoute>
      <Route path="/browse-games">
        <BrowseGames />
      </Route>
      <Route path="/game/:shortName">
        <Home />
      </Route>
      {routes.map((route) => {
        if (!selectedGame || !selectedGame.accessibility || (selectedGame && selectedGame.accessibility[route.key])) {
          return (
            <Route path={route.pathName} exact={route.exact}>
              {route.content}
            </Route>
          );
        }
      })}
      {authRoutes.map((route) => {
        if (!selectedGame || !selectedGame.accessibility || (selectedGame && selectedGame.accessibility[route.key])) {
          return <AuthRoute path={route.pathName}>{route.content}</AuthRoute>;
        }
      })}
      {/* 404 */}
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
export default AppRouter;