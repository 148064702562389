import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import ConnectLOL from "./LOL/ConnectLOL";
import ConnectCSGO from "./CSGO/ConnectCSGO";
import ConnectVL from "./VL/ConnectVL";
import NonIntegrationGame from "./non-integration/nonIntegration";
import { AppContext } from "@context/AppContext";
import useTranslated from "../../../../helpers/translationHelper";

const ConnectGame = ({ onClose, afterCreate }) => {
  const { user, selectedGame } = useContext(AppContext);
  const selectedGameName = localStorage.getItem("selectedGame")
  const [games, setGames] = useState([]);
  const [game, setGame] = useState("");

  const addgamelable=useTranslated("id_pop-up_-_create_player_2")

  const fetchGamesPlayerLimit = async (userId) => {
    if(!userId) return
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/playerLimit/${userId}`)
      .then(({ data }) => {
        setGames(data)
        console.log('playerLimitData', data)
      })
  }


  const fetchGames = async () => {
    await Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`)
      .then(({ data }) => {
        console.log('games fetched: ', data)
        setGames(data)
      })
  }

  useEffect(() => {
    console.log('user', user)
    //fetchGamesPlayerLimit(user.state._id)
    fetchGames()
  }, [])

  const afterConnect = (player) => {
    afterCreate(player);
    onClose();
  };

  const getGameData = () => {
    if (game) {
      return games.find(({ shortName }) => game === shortName);
    }

    return false;
  };
  return (
    <div>
      <div className="form-row">
        <label htmlFor="game">{addgamelable/* Add game account */}</label>
        <select 
          id="game" 
          className={`form-input ${game === "CSGO"?"csgo-sel":game === "VL"?"vl-sel":game === "LoL"?"lol-sel":''}`}
          value={game || "_DEFAULT"} 
          onChange={(e) => setGame(e.target.value)}
          >
          <option value="_DEFAULT" disabled>
            Choose game
          </option>
          {games.map(({ _id, name, shortName, limitReached }) => (
            <option key={_id} value={shortName} disabled={limitReached}>
              {name} {limitReached && "(Account Limit Full)"}
            </option>
          ))}
        </select>
      </div>

      {/* In the near future this will contain route to different components based on selected game, 
      for extra functionality such as verifying summoner name */}

      {games.map(({ _id, name, shortName }) => {
        if (game === shortName) {
          switch (shortName) {   
            case "CSGO":
              return <ConnectCSGO key={_id} gameData={getGameData()} afterConnect={afterConnect}  selectedGame={selectedGame}/>;
              break;
            case "LoL":
              return <ConnectLOL key={_id} gameData={getGameData()} afterConnect={afterConnect} selectedGame={selectedGame} />;
              break;
              case "VL":
                return <ConnectVL key={_id} gameData={getGameData()} afterConnect={afterConnect} selectedGame={selectedGame} />;
                break;
            default:
              return <NonIntegrationGame afterConnect={afterConnect} game={_id} user={user.state._id} />;
          }
        }
        // if (game === name)
        //   return <ConnectCSGO key={_id} gameData={getGameData()} afterConnect={afterConnect} />;
        // return false;
      })}

    </div>
  );
};

export default ConnectGame;
