import React, { useState, useEffect, useContext } from "react";
// import leagues_banner from "@img/leagues-banner.jpg";
import { LeagueItem, Item, Advert } from "@common";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";
import Loading from "../../_common/Loading/Loading";
import { useHistory } from "react-router-dom";

const MultiStageLeaguesTab = ({ all = false, groupName = "", groupId }) => {
  const { selectedGame } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (all) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons`, { params: { game: selectedGame._id, withMultiStageLeagues: true } }).then(
        ({ data }) => {
          console.log("data =>", data);
          // console.log("data =>",data)
          setSeasons(data);
          setLoading(false);
        }
      );
    } else {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/gameGroups/${groupId}`, { params: { withMultiStageLeagues: true } }).then(
        ({ data }) => {
          setSeasons(data.seasons);
          setLoading(false);
        }
      );
    }
  }, [all, groupId, selectedGame._id]);

  return (
    <div className="row smaller-gutter-row">
      {loading ? (
        <div className="col-12 text-center p-5">
          <Loading />
        </div>
      ) : (
        // Array(12)
        //     .fill()
        //     .map((_, i) => <Item key={i} loading column="3" />)
        seasons.map(
          // console.log("seasons map ==>>",seasons),
          ({ name, year, multiStageLeagues = [] }) =>
            !!multiStageLeagues.length && (
              <>
                <div className="col-12 pb-4">
                  <span className="text-22 uppercase bold">
                    {name}&nbsp;
                    <span className="color-primary outline">{year}</span>
                  </span>
                </div>
                {multiStageLeagues.map(({ _id, name, stages }) =>
                  stages[0].stageType === "league" ? (
                    <LeagueItem
                      key={_id}
                      title={name}
                      second_line={
                        <React.Fragment>
                          <img
                            src={`https://flagcdn.com/w20/${
                              stages[0].league?.country?.code ? stages[0].league?.country?.code?.toLowerCase() : "dk"
                            }.png`}
                            alt="Danish flag"
                          />
                          {stages[0].league?.country?.name ?? "Danish"}
                        </React.Fragment>
                      }
                      third_line={`${stages[0]?.league?.teams?.length} teams participating`}
                      tag={stages[0].league?.leagueDivisionName}
                      column="3"
                      image={stages[0].league?.thumbnailImage}
                      link_to={`/single-multi-stage-league/${_id}`}
                      leagueFinished={stages[0].league?.leagueFinished}
                    />
                  ) : (
                    <LeagueItem
                      key={_id}
                      title={name}
                      second_line={
                        <React.Fragment>
                          <img
                            src={`https://flagcdn.com/w20/${
                              stages[0].tournament?.country?.code ? stages[0].tournament?.country?.code?.toLowerCase() : "dk"
                            }.png`}
                            alt="Danish flag"
                          />
                          {stages[0].tournament?.country?.name ?? "Danish"}
                        </React.Fragment>
                      }
                      third_line={`${stages[0]?.tournament?.teams.length} teams participating`}
                      tag={stages[0].tournament?.tournamentDivisionName}
                      column="3"
                      image={stages[0].tournament?.thumbnailImage}
                      link_to={`/single-multi-stage-league/${_id}`}
                      tournamentFinished={stages[0].tournament?.tournamentFinished}
                    />
                  )
                )}
              </>
            )
        )
      )}

      <div className="container-fluid" style={{ paddingTop: "36px", paddingBottom: "36px", overflow: "hidden" }}>
        <Advert width={1660} height={168} refName="HOMEPAGE_1660_168_WIDE" style={{ margin: "0 auto" }} />
      </div>
    </div>
  );
};

export default MultiStageLeaguesTab;
